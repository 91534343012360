.c-select-search {
  margin-bottom: 5rem;
  .c-form__row {
    margin: 0 0 .5rem;
  }

  &__input {
    position: relative;
    input {
      padding: .5rem 4.5rem .5rem 1.8rem;
      border: 1px solid;
      border-radius: 1rem;
      height: 4.5rem;
      font-size: 1.5rem;
      width: 100%;
    }
    i {
      position: absolute;
      top: 2rem;
      right: 1.4rem;
      font-size: 0.8rem;
    }
  }
  &__results {
    width: 100%;
    max-height: 22rem;
    overflow-y: auto;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;


    &__single {
      padding: .5rem 1.8rem;
      border: none;
      text-align: left;

      @media screen and (max-width: 650px) {
        padding: 1rem 1.8rem;
      }

      &:last-of-type {
        border-bottom: 1px solid transparent !important;
      }

      span {
        font-size: 1.6rem;
        strong {

        }
      }
    }
  }
}
